/* -------------------------------------------------------------------------
 Webdesign Volta Footer
---------------------------------------------------------------------------- */

p.volta {
	width: 100%;
	height: 16px;
	z-index: 9999;
	position: absolute;
	bottom: 15px;
	text-align: center;

	a {
		display: block;
		text-indent: -9999px;
		width: 16px;
		height: 16px;
		background: url(../img/volta.gif) top left no-repeat;
		filter: alpha(opacity=60);
		-moz-opacity: 0.6;
		-khtml-opacity: 0.6;
		opacity: 0.6;
		margin: 0 auto;

		&:hover {
			background: url(../img/volta-bl.gif) center bottom no-repeat;
		}
	}
}
