.sidebar {

	@include xm {
		display: flex;
	}

	a {
		text-decoration: none;
		font-size: 1.3rem;
		border-bottom: none;
		color: $shade1;
		display: block;
		line-height: 1.4;
		margin-bottom: 1em;

		&:hover{
			text-decoration: underline;
		}
	}

	p a{
		padding: 0;
		margin: 0;
	}

	.block-white { //li
		border-right: 1px $shade4 dotted;
		border-left: 1px $shade4 dotted;
		margin-top: 1em;

		@include xm {
			border-right: none;
			width: 33.333333%;

			&:last-child {
				border-right: 1px $shade4 dotted;
			}
		}

		article {
			padding-top: 0;
		}

		h2 {
			margin-top: 0;			
			color: $shade2;
			margin-bottom: 0.5em;
		}

		p {
			margin-top: 0.5em;
			color: $shade3;
			padding: 0;
			color: $purple1;
			font-size: 1.3rem;
			line-height: 2;
		}

		input[type="text"]{
			width: 90%;
			margin-bottom: 1em;
			margin-top: 0em;
		}

		input[type="submit"]{
			cursor: pointer;
		}

		.btn{
			text-decoration: none;
		}

		.border{
			border-bottom: 2px $shade4 dotted;
			width: 30%;
		}
	}

	.border-left {
		display: block;
		margin-top: 2em;
		border-bottom: none;
		border-left: 1px solid $purple1;
		padding-left: .6em;
		color: $purple1;
	}
}

.col-4 {
	.sidebar {
		flex-wrap: wrap;

		.block-white {
			width: 100%;

			@include xm {
				width: 33%;
			}

			@include xl {
				width: 100%;
			}

			&:last-child {
				border-right: none;
			}
		}
	}
}