/* -------------------------------------------------------------------------
 Breadcrumbs
---------------------------------------------------------------------------- */

.breadcrumb{
	font-size: 1.2rem;
	margin-bottom: 10px;
	margin-top: 10px;
	color: $shade4;
	font-weight: $DINlight;
	clear: both;

	ul{
		overflow: hidden;

		li{
			margin-left: 0.5em;
			margin-right: 0.5em;
			float: left;

			&:first-child{
				margin-left: 0;
			}

			a{
				text-decoration: none;
				color: $purple1;
				border-bottom: none;
			}

			a:after{
				content: ">";
				margin-left: 1em;
			}
		}
	}
}