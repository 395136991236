/* -------------------------------------------------------------------------
 Colors
---------------------------------------------------------------------------- */

$white: 			#ffffff;
$black: 			#231f20;

/*New*/
$purple1:			#000049;
$purple2:			#000049;
$purple3: 			#bfbfd1;
$purple4:			#9E9AB1;
$purple5:         	#2b2c49;
$purple6:			#595989;
$purple7:			#cacfcd;

$shade1:			#3c3c3b;
$shade2:			#3c3c3b;
$shade3:			#788582;
$shade4:			#999999;
$shade5:			#CFD4D2;
$shade6:			#BDC4C2;
$shade7:			#C7CDCF;
$shade8:			#e5e5ec;
$shade9: 			#DEE2E1;
$shade10: 			#BABDBC;
$shade11:           #f7f8f8;
$shade12:           #b2b2c8;
$shade13:           #f2f2f6;


$green1:			#65b846;
$green2:			#65b846;
$green3:			#9DCB92;
$green4:			#a3d490;



/* -------------------------------------------------------------------------
 Fonts
---------------------------------------------------------------------------- */


$OS: 'Open Sans', sans-serif;

$FSRegular: 'Fira Sans Regular', sans-serif;
$FSMedium: 'Fira Sans Medium', sans-serif;

/*New*/

$DINlight:   400;
$DINregular: 400;
$DINitalic:  400;
$DINbold:    700;

$BR:         'Bauer Bodoni W01 Roman';

/* -------------------------------------------------------------------------
 Breakpoints
---------------------------------------------------------------------------- */

$bp1: 				320px;
$bp2: 				480px;
$bp3: 				768px;
$bp4: 				940px;
$bp5: 				1210px;
$bp6: 				1340px;