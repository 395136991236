$mobile-width: 49.938em; /* 799px; */
$small-width: 0;
$medium-width: 30em; /* 480px; bp2 */
$xmedium-width: 48em; /* 768px; bp3 */
$large-width: 58.75em; /* 940px; bp4 */
$xlarge-width: 75.625em; /* 1210px; bp5 */
$xxlarge-width: 83.75em; /* 1340px; bp6 */


$breakpoints: (
  mobile: $mobile-width,
  small: $small-width,
  medium: $medium-width,
  xmedium: $xmedium-width,
  large: $large-width,
  xlarge: $xlarge-width,
  xxlarge: $xxlarge-width
);

$breakpoint-classes: (small medium xmedium large xlarge xxlarge);


@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$small-width}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$medium-width}) {
    @content;
  }
}

@mixin xm {
  @media (min-width: #{$xmedium-width}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$large-width}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$xlarge-width}) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: #{$xxlarge-width}) {
    @content;
  }
}



@mixin only-sm {
  @media (min-width: #{$small-width}) and (max-width: #{$medium-width}) {
    @content;
  }
}

@mixin only-md {
  @media (min-width: #{$medium-width}) and (max-width: #{$xmedium-width}) {
    @content;
  }
}

@mixin only-xm {
  @media (min-width: #{$xmedium-width}) and (max-width: #{$large-width}) {
    @content;
  }
}

@mixin only-lg {
  @media (min-width: #{$large-width}) and (max-width: #{$xlarge-width}) {
    @content;
  }
}

@mixin only-xl {
  @media (min-width: #{$xlarge-width}) and (max-width: #{$xxlarge-width}) {
    @content;
  }
}

@mixin only-xxl {
  @media (min-width: #{$xxlarge-width}) {
    @content;
  }
}

@include sm {
  body {
    margin: 0;
  }

  .hide-medium {
    display: block !important;
  }

  .show-medium {
    display: none !important;
  }

  .hide-xmedium {
    display: block !important;
  }

  .show-xmedium {
    display: none !important;
  }

  .hide-large {
    display: block !important;
  }

  .show-large {
    display: none !important;
  }

  .hide-xlarge {
    display: block !important;
  }

  .show-xlarge {
    display: none !important;
  }

  .hide-xxlarge {
    display: block !important;
  }

  .show-xxlarge {
    display: none !important;
  }
}

@include md {
  body {
    margin: 0;
  }

  .hide-medium {
    display: none !important;
  }

  .show-medium {
    display: block !important;
  }
}

@include xm {
  body {
    margin: 0;
  }

  .hide-xmedium {
    display: none !important;
  }

  .show-xmedium {
    display: block !important;
  }
}

@include lg {
  body {
    margin: 0;
  }

  .hide-large {
    display: none !important;
  }

  .show-large {
    display: block !important;
  }
}

@include xl {
  body {
    margin: 0;
  }

  .hide-xlarge {
    display: none !important;
  }

  .show-xlarge {
    display: block !important;
  }
}

@include xxl {
  body {
    margin: 0;
  }

  .hide-xxlarge {
    display: none !important;
  }

  .show-xxlarge {
    display: block !important;
  }
}


body:after {
  display: none;
  background-color: rgba(0,0,0, .5);
  color: white;
  padding: .3em;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  pointer-events: none;

  @include sm {
    content: "Small";
  }

  @include md {
    content: "Medium";
  }

  @include xm {
    content: "XMedium";
  }

  @include lg {
    content: "Large";
  }

  @include xl {
    content: "Xlarge";
  }

  @include xxl {
    content: "XXlarge";
  }
}