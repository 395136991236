.single, .page, .page-template-tpl_blog, .page-template-tpl_producten{
	h1, h2{
		// padding-bottom: 1em;
		color: $purple1;
		font-size: 2.4rem;
		font-weight: 500;
	}
	.heading1{
		text-align: left;
	}
	.banner{
		width: 100%;
		height: 200px;
		background-size: cover;
		margin-bottom: 2em;

		@media screen and (min-width: $medium-width) {
			height: 400px;
		}
	}

	.grid.container article{
		margin: 1em 0 0;
		h1, h2, p{
			padding: 0;
		}
	}

	.sidebar{
		margin-top: 1em;
		.heading1{
			padding: 0;
		}
		.block-white{
			width: 100%!important;
			border-left: none;
			border-right: none!important;

			@media screen and (min-width: $xmedium-width) {
				border-left: none;
				border-right: 1px $shade4 dotted;
				padding-left: 2em;
			}
		}

		.border{
			border-bottom: 2px dotted $purple1;
		}

		p{
			color: $shade1;
			line-height: 1.5;
			padding: 0 0 3em;
		}

		.btn{
			font-weight: $DINlight;
			font-size: 1.4rem;
			background-color: $green1;
			color: $white;
			padding: .5em 1em;
			min-width: 80px;
			text-align: center;
			text-decoration: none;
			display: inline-block;
			border-bottom: none;
			border-radius: 5px;
			border-bottom: 3px solid $green3;
			color: $white;
			display: table;

			font-size: 1.2rem;
			@media screen and (min-width: $xmedium-width) {
				font-size: 1.8rem;
			}

			&.blue{
				background: $purple1;
				padding: .5em 1em;
				border-bottom: 3px solid $purple6;
			}
			&.download{
				font-size: 1.4rem;
				text-align: left;
			}
		}

		.advantages-container{
			background: $green1;
			color: $white;
			padding: 1em 2em;

			p{
				color: $white;
				padding: 0 0 1em;

				font-size: 1.2rem;
				@media screen and (min-width: $xmedium-width) {
					font-size: 2rem;
				}
			}
			.btn{
				width: auto;
			}
		}
	}
}