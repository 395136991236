.block-light { //li
	width: 100%;
	margin: 1em 0;
	background-color: $shade8;
	border-radius: 7px;
	border-top: 5px $purple3 solid;
	padding: 1em;
	box-sizing: border-box;

	.border {
		border-bottom: 2px $white dotted;
		width: 30%;
	}
	.heading1{
		padding: 0;
	}
	a{
		border: none;
	}
	.article-title{
		.subheading{
			font-size: 1.5rem;
			font-weight: 500;
			text-decoration: none;
			border-bottom: 1px solid transparent;
			transition: all .3s;
		}
		&:hover{
			.subheading{
				border-bottom: 1px solid $purple1;
			}
			i{
				transform: translateX(1em);
			}
		}
	}
}