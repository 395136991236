.categories{
	margin-bottom: 1em;

	.category-item{
		display: block;
		padding: 1em 0;
		border-bottom: 1px dotted $shade6;
		&:last-child{
			border: none;
		}
	}

}