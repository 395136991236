.heading1, .heading2, .subheading{
	display: block;
	text-align: center;
	color: $purple1;
	font-weight: $DINregular;
	font-weight: 900;
	margin-bottom: .5em;
	font-size: 2rem;
	@media screen and (min-width: $xmedium-width) {
		font-size: 2.8rem;
	}

}
.heading1{
	line-height: 1.5;
	padding: 1em 0;
}
.heading2{
	margin-bottom: 0;

	font-size: 1.8rem;
	@media screen and (min-width: $xmedium-width) {
		font-size: 2.2rem;
	}
}
.subheading{
	color: $shade1;

	font-size: 1.2rem;
	@media screen and (min-width: $xmedium-width) {
		font-size: 1.4rem;
	}
}
.page-header{
	padding: 0;
	font-weight: 800!important;
}