@media screen and (min-width: $small-width) {
 
    .grid{
		&.personen{
			.block-persoon{
				width: 100%;
				margin-right: 0;

				article{
					padding: 0;
				}

				img{
					width: 150px;
					float: left;
					margin-right: 10px;
				}
			}
		}
	}	
}

@media screen and (min-width: $xmedium-width) {

	.tabs{
		ul{
			 border-bottom: 3px solid $shade8;

			 li{
				width: 33%;
				text-align: center;
				border-radius: 5px 5px 0 0;
			 }
		}
	}

	.page-template-tpl_product_groepen-php{
		h3{
			clear: both;
			padding-top: 10px;
		}
	}

	 .single-acties{
		.menu-item-55{
			border-bottom: 3.5px $purple3 solid;
		}
	 }

	.grid{
	  &.personen {
		max-width:100%;
	  }

		&.personen{
			.block-persoon{
				width: 49%;
				margin-right: 5px;
			}
		}
	}

	.product{
		article{

			.gerelateerd-side{
				width: 30%;
				float: left;
				margin-top: 10px;
			}
		}	
	}



	#gform_fields_1{
	  #field_1_3, #field_1_5{
		float: left;
		width: 50%;
		clear: none;

		input{
		  width: 95%;
		}

		&.gfield_error{
		  width: 47%;
		}
	  }

	  #field_1_3{
		input{
		  width: 93%;
		  margin-left: 2%;
		}
	  }

	  .ginput_left{
		float:left;
		width:50%;
	  }
	  .ginput_right{
		float:right;
		width:50%;
	  }
	}
}
	

@media screen and (min-width: $large-width) {


	 body > div#cookiewarning{
	  div#back{
		width:980px;
		div{
		  p{
			width: 70%;
		  }

		  #buttons{
			width:30%;
			text-align:left;
			margin-top: 0;
		  }
		}
	  }
	} 
		
	.breadcrumb{
		margin-top: 10px;
	}
}

 
@media screen and (min-width: $xlarge-width) {

	article{
		p{
			padding-left: 0;
			padding-right: 0;
		}
	}

	.container{
		width: 1200px;
		// padding: 0 10px;
	}

	.col-4 {
		width: 33.5%;
	}

	.col-4-3 {
		padding-right: 2em;
		width: 66.5%;
	}

	.list-menu{
			max-width: 731px;
	}
}


@media screen and (min-width: $xxlarge-width) {


 
}
