.gform_wrapper{
	div.validation_error{
		color: $purple1!important;
		border-top: $purple1!important;
		border-bottom: $purple1!important;
	}
	.validation_message{
		color: $purple1!important;
	}
	li.gfield.gfield_error{
		border-top: $purple1!important;
		border-bottom: $purple1!important;
	}
	.gfield_description{
		padding-top: 10px!important;
	}
}
.gform_confirmation_message{
	padding: 6em 0 10em 0;
}