.block-tips-tricks{
	@media screen and (min-width: $xmedium-width) {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		align-content: flex-start;
	}

	.heading1{
		text-align: left;
	}
	.heading2, .subheading{
		text-align: left;
		display: inline;
	}
	.heading2{
		font-weight: 600;
	}
	.subheading{
		font-size: 1.5rem;
	}
	a i{
		font-size: .8rem;
		transform: translateY(-1px);
	}
	.article-title{
		margin: .5em 0;
		.subheading{
			line-height: 1.3;
		}
	}
	.article-footer{
		display: block;
		.subheading{
			color: $purple1;
			font-size: 1.1rem;
			font-weight: 200;
		}
	}

	.block-articles{
		margin-bottom: 3em;

		@media screen and (min-width: $xmedium-width) {
			margin-bottom: 0;
		}

		.article-small-wrapper{
			display: block;
			@media screen and (min-width: $xmedium-width) {
				display: flex;
				justify-content: space-between;
			}
		}
		.article-big{
			width: 100%;
			margin-bottom: 2em;
		}
		.article-small{
			width: 100%;

			@media screen and (min-width: $xmedium-width) {
				width: 49%;
				float: left;
			}
		}
		.article-big, .article-small{
			img{
				width: 100%;
			}
		}
		.width-33{
			width: 32%!important;
		}
	}

	.block-side{
		@include xm {
			width: 32.5%;
		}
	}

	.block-light{
		margin: 0;

		a i{
			color: $black;
			transition: all .3s;
		}
		.article-small{
			margin-bottom: 1em;
		}

		.most-read-wrapper, .our-favourites-wrapper{
			.article-title{
				margin: 0;
			}
		}
	}

	.mailbox-wrapper{
		background: $green1;
		color: $white;
		padding: 1em;
		width: 100%;

		h3{
			font-weight: 700!important;
		}

		.gform_wrapper {
			form .gform_body .ginput_complex input[type=text] {
				width: 100%!important;
			}
			input[type=text], select, textarea {
				width: 100%!important;
				padding: .5em 1em!important;
			}
			ul.gform_fields li.gfield{
				padding: 0!important;
			}
			.top_label .gfield_label{
				display: none;
			}
			input[type=submit]{
				background: $purple1;
				padding: .5em 1em!important;
				border-bottom: 3px solid $purple6;
			}
			.gform_footer{
				padding-bottom: 0!important;
			}
		}
	}

	.article-small{
		.article-title{
			display: inline-block;
			line-height: 1.2;
		}
		.heading2{
			font-size: 1.2rem;
			line-height: 1.2;

			@media screen and (min-width: $xmedium-width) {
				font-size: 1.6rem;
			}
		}
	}
}


.single-product{
	.block-tips-tricks .mailbox-wrapper{
		width: 100%;
		margin: 0 -1em;
		
		@media screen and (min-width: $xmedium-width) {
			// width: 33%;
			margin: 0 0 0 1em;
		}
	}
}

.green{
	color: $green1!important;
}







