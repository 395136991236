.page-template-tpl_blog{
	.single-post {
		.product-tekst {
			p {
				margin-bottom: 1.5em;
			}
		}
	}

	.social-icons { //ul
		display: inline-block;
		margin-left: 1em;

		a {
			border-bottom: none;
			margin: 0 .3em;
		}
		.social-icon { //li
			display: inline-block;
		}
	}
}

