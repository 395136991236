.container {
	width: 100%;
	padding: 0 1rem;
	margin: 0 auto;
	box-sizing: border-box;
}

.row {
	clear: both;
	overflow: hidden;
}

[class^="col-"],
[class*=" col-"] {
	float: left;
	box-sizing: border-box;
}

.col-1 {
	width: 100%;
}
.col-2 {
	width: 50%;
}
.col-3 {
	width: 100%
}

.col-6 {
	width: 100%;
}

.col-3-2 {
	width: 100%
}
.col-4 {
	width: 100%;
}

.col-4-3 {
	width: 100%;
}


@media screen and (min-width: $xmedium-width) {

	.col-3 {
		width: 33.33333333%
	}

	.col-3-2 {
		width: 67%
	}

	.col-6 {
		width: 66.66666666%
	}

}