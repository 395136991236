.blog-article{
	position: relative;
	padding-bottom: 2em;


	display: block;
	@media screen and (min-width: $xmedium-width) {
		display: flex;
	}


	.blocks-light{
		width: 100%;
	}

	img, .article-content{
	}
	img{
		width: 100%;
		@media screen and (min-width: $xmedium-width) {
			max-width: 400px;
			align-self: center;
		}
	}
	.article-content{
		padding-left: 1em;
	}

	.article-footer{
		margin-bottom: 1em;
		span{ display: inline; }
		a{
			display: block;
		}
	}

	.article-title h2, .subheading{
		text-align: left;
		padding: 0;
	}
}