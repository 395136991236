/* -------------------------------------------------------------------------
 Header 
---------------------------------------------------------------------------- */

#site-header {
	a {
		border-bottom: none;
	}
}

#top-bar {
	background-color: $green1;

	a {
		padding: .6em 1em;
		display: block;
		transition: all .1s ease-in-out;
	}

	.svg {
		width: 15px; max-height: 10px;
		display: inline-block;
		margin-right: 5px;
	}

	.contact {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		a {
			display: block;
			color: $white;

			&:hover {
				color: $purple2;
				background-color: $white;
			}
		}
	}

	.menus {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		@include xm {			
			justify-content: space-between;
		}
	}

	.top-menu {
		display: flex;
	}

	.white-buttons { //ul

		a {
			&:hover {
				color: $white;
				background-color: $green1;
			}
		}

		li {
			background-color: $white;
			border-right: 1px solid $shade12;

			&:last-child {
				border-right: none;
			}
		}
	}

	.green-buttons { //ul
		a {
			color: $white;

			&:hover {
				color: $purple2;
				background-color: $white;
			}
		}

		li {
			border-right: 1px solid $green4;

			&:last-child {
				border-right: none;
			}
		}
	}
}

#banner {
	background: $purple1 url('../img/banner-bg.jpg') no-repeat top center;
	background-size: cover;
	border-bottom: .5em solid $green1;

	.container {
		position: relative;
		padding-bottom: 1em;
	}

	a {
		color: $white;

		&:hover {
			color: $white - 20;
		}
	}

	.contact-wrapper {
		padding: 2.5em 1em;

		.svg {
			width: 15px; max-height: 10px;
			display: inline-block;
			margin-right: 5px;
		}
	}
	
	.search {
		.search-box {
			margin: 2.5em 1em 0 auto;
			width: 80%
		}

	}

}

.logo-link {
	border: none;
}

.logo {
	a {
		display: block;
		max-width: 22rem;
		margin: 0 auto;

		&:hover {
			.svg {
				opacity: .8; 	
			}
		}

		@include xm {
			max-width: 26rem;
		}
	}

	.svg {
		box-sizing: border-box;
		padding: 2em 1.5em 1.3em;
		display: block;
		transition: all .2s ease-in-out;
	}
}









