/* Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 100%;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    @include lg {
		width: 30%;
    }

    .slide {
    	position: relative;
    	border-bottom: none;
    }

	.button {
		position: absolute;
		right: 1em; bottom: 1em; left: 1em;
		background-color: $green1;
		color: $white;
		padding: .4em .8em;
		border-radius: 3px;
		box-shadow: .1em .2em 0px $green4;
	}
}

.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}
.slick-list:focus {
    
}
.slick-list.dragging {

}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}