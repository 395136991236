.home-block {
	@include lg {
		display: flex;
	}
}
.block-1 {
	// Content
	.content {
		background-color: $shade8;
		border-radius: 5px;
		font-weight: $DINregular;
		background-color: $shade8;
		padding: 3em 0;
		text-align: center;

		span {
			color: $green1;
		}

		main{
			max-width: 600px;
			margin: auto;
		}
		.heading1{
			padding: 0;
			font-weight: 800;
			font-size: 2.9rem;
		}
	}
}

.block-2, .block-3 {
	@media screen and (min-width: $xmedium-width) {
		.blocks {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}
	}
}

.block-2 {
	margin: 2em auto;
	box-sizing: border-box;
	width: 100%;

	.blocks{
		margin-bottom: 3em;
	}

	.link-item { //li
		position: relative;
		width: 100%;
		height: 300px;
		@include xl {
			width: 49%;
		}
		a{
			transition: all .3s;
		}
		a{
			font-size: 2rem;
			font-weight: 700;

			color: $white;
			background-color: $green1;
			border-radius: 5px;
			border-bottom: 3px solid $green3;

			position: absolute;
			bottom: 1em;
			left: 1em;
			right: 1em;
			padding: .5em 1em;
			text-align: center;

			display: flex;
		    align-items: center;
		    height: 70px;

			i{
				position: absolute;
				right: 1em;
				transition: transform .3s;
			}
			span{
				text-align: left;
			}
			&:hover {
				background-color: $purple1 + 20;
				color: $green1;
				i{
					transform: translate(10px, 0);
				}
			}

			// background-color: $purple1;
			// background: url(https://images.unsplash.com/photo-1519665725046-0e6e771d4d5b?ixlib=rb-0.3.5…EyMDd9&s=57364e8…&auto=format&fit=crop&w=1500&q=80) center center no-repeat;
			// background-size: cover;

			// color: $white;
			// padding: 1em 2.5em 1em 1em;
			// display: block;
			// border-left: 6px solid $purple3;
			// border-bottom: none;
			// line-height: 1.3;
			// position: relative;
			// display: block;

			// height: 100%;
			// padding: 0;
			// display: flex;
			// align-items: center;
			// margin-bottom: 1em;

			// font-size: 2rem;
			// @media screen and (min-width: $xmedium-width) {
			// 	font-size: 2.9rem;
			// }

			// span{
			// 	padding: 1em;
			// }
			// i{
			// 	position: absolute;
			// 	right: 1em;
			// 	top: 50%;
			// 	transform: translateY(-50%);
			// }

			// &:hover {
			// 	background-color: $purple1 + 20;
			// 	color: $green1;
			// 	i{
			// 		transform: translate(10px, -50%);
			// 	}
			// }
		}
	}
}

.block-3{
	margin-bottom: 1em;
	i{
		display: block;
		text-align: center;
		font-size: 11rem;
		padding: 0 0 0.2em 0;
		color: $purple1;
	}
	.heading2{
		margin-bottom: 1em;
	}
	.subheading{
		color: $purple1;
		font-weight: 500;
	}
	.block-light{
		width: 100%;
		@media screen and (min-width: $xmedium-width) {
			width: 32%;
		}
	}
}