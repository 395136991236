/* -------------------------------------------------------------------------
 Content blocks
---------------------------------------------------------------------------- */


.grid {
    &.container{
      article{
        margin: 10px;
      }
    }


    &:after {
    	content: '';
	    display: inline-block;
	    width: 100%;
    }

	li.block {
		display: block;
    	text-align: left;
    	
	
		article {
			padding: 10px 24px;
			overflow: hidden;
	    	box-sizing: border-box;

			header {

				
			}

			p {
				font-size: 1.4rem;
			}

			footer {

			}
		}
		
		&.block-header-gray{
			background-color: $shade8;
			border-radius: 5px 5px 0px 0px;
			font-weight: $DINregular;


			h1{
				color: $purple1;
				line-height: 34px;
				font-size: 2.9rem;
				font-weight: $DINregular;
				font-weight: 900;
				margin-bottom: .5em;
			}

			span {
				color: $green1;
			}

			p {
				padding: 0;
			}
		}


		



		&.block-header-photo{

			.slide{
				width: 33.3%;
				float: left;
				border: none;

				img{
					width: 100%;
					border: none;
				}
			}

			.triangle{
				display: none;
				width: 0px;
				height: 0px;
				width: 0px;
				height: 0px;
				border-style: solid;
				border-width: 0 25px 30px 0;
				border-color: transparent $green2 transparent transparent;
				position: relative;
				float: right;
				left: 1px;
				top: -160px;
			}
		}

		&.block-header-green{
			background-color: $green2;
			border-radius: 0px 0px 5px 5px;

			article{
				padding-left: 0px;
				padding-right: 0px;
				padding-bottom: 15px;

				p{
					color: $white;
					font-size: 1.5;
					line-height: 23px;
					font-weight: $DINlight;
					margin-top: 0em;
					width: 27.3%;
					float: left;
					padding-left: 3%;
					padding-right: 3%;
				}

				.circles{
					margin-left: 10%;
				

					.circle{
						border-radius: 50%;
						width: 12px;
						height: 12px; 
						background-color: $white;
						position: relative;
						float: left;
						margin-right: 1em;
						cursor: pointer;

						&.active{
							background-color: $purple1;
						}
					}
				}
			}
		}

		&.block-header-wide{
			height: auto;

			article{
				padding-top: 25px;

				p{
					color: $shade1;
					font-weight: $DINlight;
					font-size: 1.8rem;
					border-right: 1px $purple1 dotted;
					padding-right: 1em;

					a{
						background-color: $shade9;
						color: $shade1;
						border-bottom: 1px $black dotted;
						padding-left: 5px;
						padding-right: 5px;
					}
				}
			}
		}
	}

	&.personen{
		max-width: 730px;
		margin-top: 25px;
		list-style: none;
		margin-left: 0;

		.block-persoon{
			width: 100%;
			background-color: $shade8;
			margin-bottom: 10px;
			display: inline-block;
			float: left;

			img{
				width: 100%;
			}

			h3{
				color: $purple1;
			}

			p{
				color: $shade4;
				font-size: 1.6rem;

				a{
					border-bottom: none;
					text-decoration: underline;
					display: inline-block;
					margin-top: 1em;
					color: $shade4;
					font-size: 1.3rem;
				}
			}
		}
	}
}
