/* -------------------------------------------------------------------------
 Footer
---------------------------------------------------------------------------- */

footer {
	position: relative;

	.footer-menu { //section
		border-top: 2px solid $green1;
		background-color: $shade8;
		color: $purple1;
		width: 100%;
		overflow: hidden;

		a {
			font-weight: 700;
			text-decoration: none;
			margin-bottom: 0.3em;
			display: inline-block;
			color: $purple1;
			border-bottom: none;
			font-weight: $DINlight;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	#menu-footer { //ul
		margin: 2em;
		overflow: hidden;
		text-align: center;

		@include md {
			display: flex;
			flex-wrap: wrap;
		}

		@include lg {
			flex-wrap: nowrap;
		}

		.menu-item {
			box-sizing: border-box;
		}

		> .menu-item { //li

			@include md {
				text-align: left;
				width: 50%;
				padding-right: 1em;
			}

			@include xm {
				width: 33.33333%;
			}

			> a {
				margin-bottom: .7em;
			}
		}

		.menu-item-has-children {
			a {
				font-size: 1.5em;
			}
		}

        .sub-menu {
        	a {
        		font-weight: 900;
        		font-size: 1em;
        	}

        	li {
        		margin-bottom: 1.5em;
        	}

        	.sub-menu {
        		a {
        			font-weight: 400;
        		}

            	li {
            		margin-bottom: 0;
            	}
        	}
        }
	}

	.footer-bottom {
		background-color: $purple1;
		color: $purple7;
		font-size: 1.3rem;
		overflow: hidden;
		padding-top: 3em;
		text-align: center;
		font-weight: $DINlight;
		padding-bottom: 5em;

		.site-info {
			display: inline;
			padding-right: 1.5em;
		}

		.menu-footer-links-container {
			display: inline-block;

			ul {
				display: inline;

				li {
					display: inline-block;
					padding: 0 1.5em;

					a {
						color: $purple7;
						text-decoration: none;
						font-weight: $DINlight;
					}
				}
			}
		}
	}

	#menu-footer-onder{
		margin-left: 1.5em;
	}

	// .footer-blocks {

	// 	&.footer-light {
	// 		background-color: $shade8;
	// 	}

	// 	&.footer-dark {
	// 		background-color: $shade3;
	// 		border-bottom: 2px dotted $shade2;

	// 		.grid .block article p {
	// 			height: 7em;
						
	// 		}
	// 	}

	// 	.grid {

	// 		.block {

	// 			article {
	// 				background-color: transparent;
	// 				border-right: 2px solid $shade5;
	// 				padding: 0;
	// 				padding-left: 30px;
	// 				margin-top: 25px;
	// 				height: auto;
	// 				color: $shade3;

	// 				header {

	// 					h1 {
	// 						margin: 0;

	// 					}
	// 				}

	// 				p {
	// 					padding: 0.5em 1em 1em 0;
	// 					height: 3em;
	// 				}
	// 			}
	// 		}

	// 	}

	// }
}