#main-menu {
	margin-top: -3em;
	position: relative;
	z-index: 2;

	@include xm {
		margin-top: -2.5em;
	}

	.toggle-wrapper { //form
		position: relative;
		display: flex;
		justify-content: space-between;
		font-size: .8em;
		padding-bottom: 3em;

		@include xm {
			padding: 0;
		}
	}
	.slider {
		margin-top: 0!important;
		background: none!important;
	}
	.mobile-toggle { //input
		display: none;



		&:focus,
		&:active {
			outline: none;
		}

		&:checked {

			& ~ label {
				.slider {
					transform: translateY(-1em);
				}

				.hamburger {
					background-color: transparent;

					&:before {
						transform: rotate(45deg);
						top: 0;
					}

					&:after {
						transform: rotate(-45deg);
						bottom: 0;
					}
				}
			}

			& ~ .search-trigger {
				&:before{
					width: 0;
					border-width: 1px;
					border-radius: 0;
					transform: rotate(45deg);
					top: -3px;
					left: 14px;
					height: 20px;
				}

				&:after {
					width: 21px;
					left: 5px;
					top: 7px;
				}
			}

			& ~ .slideout-wrapper {
				max-height: 100rem;
			}

			& ~ .page-overlay {
				max-height: 100vh;
			}
		}
	}

	.mobile-trigger { //label
		font-size: 1.4em;
		font-family: droid-serif;
		cursor: pointer;
		padding-left: 2.5em;
		color: $white;

		@include xm {
			display: none;
		}
		
		.slider-wrapper {
			height: 1em;
			overflow: hidden;	
			display: inline-block;
			line-height: 1;
		}

		.slider {
			display: inline-block;
			transform: translateY(0em);
			position: relative;
			transition: all .2s ease-in-out;
		}

		.hamburger {
			width: 17px; height: 2px;
			display: inline-block;
			background-color: $white;
			position: absolute;
			top: .5em; left: 1em;

			&:before,
			&:after {
				content: " ";
				width: 17px; height: 2px;
				position: absolute;
				background-color: $white;
				transition: all .2s ease-in-out;
			}

			&:before {
				top: -.3em;
			}

			&:after {
				bottom: -.3em;
			}
		}
	}

	.page-overlay {
		position: absolute;
		top: 75%; right: 0; left: 0;
		background-color: rgba(0, 0, 0, .5);
		display: block;
		height: 100vh;
		overflow: hidden;
		transition: max-height .4s ease-in-out;
		max-height: 0;
		margin-left: -1rem;
		margin-right: -1rem;
	}

	.slideout-wrapper {
		box-sizing: border-box;
		max-height: 0;
		transition: max-height .4s ease-in-out;
		background-color: $shade8;
		overflow: hidden;
		position: absolute;
		top: 75%; left: -1rem; right: -1rem;
		z-index: 999;
		display: flex;
		flex-wrap: wrap;

		@include xm {
			max-height: 100%;
			background-color: $shade13;
			border-radius: .5em;
			position: relative;
			flex-wrap: nowrap;
			top: 0; left: 0; right: 0;
		}
	}


	// MENU
	.menu-wrapper {
		@include xm {
			width: 100%;
		}
	}

	.main-menu-wrapper {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		padding: 0 .9em;

		@include xm {
			flex-wrap: nowrap;
		}
	}

	.menu {
		padding: 1.5em 0 0;
		width: 100%;
		text-align: center;
		font-size: 1.8rem;
		

		&:last-child {
			padding-bottom: 1.5em;
		}

		// @include md {
		// 	width: 50%;
		// }

		@include xm {
			display: flex;
			padding: 2em 0 2.5em;
			font-size: 1.5rem;
			justify-content: center;
			min-height: 10rem;
		}

		li {
			margin-bottom: .2em;
			display: block;

			&:nth-last-child(-n+2){
				border: none;
			}

			@include xm {
				margin: 0;
				padding: 0 1em;
				text-align: center;
				border-right: 1px dotted $purple1;
				display: flex;
				align-items: center;
				
			}
			&.btn{
				max-width: 200px;
				margin: auto;
				@media screen and (min-width: $xmedium-width) {
					max-width: none;
					margin: inherit;
				}
				a{
					color: $white;
				}
			}
		}

		a {
			display: block;
			padding: .3em;

			@include xm {
				font-size: 1.3rem;
				padding: 0 .3em;
			}

			@include lg {
				font-size: 1.4rem;
			}

			@include xl {
				font-size: 1.5rem;
				padding: 0 .8em	;
			}
		}
	}

	.axa {
		display: block;
		margin: 2em auto;
		display: flex;
		align-items: flex-start;

		@include xm {
			margin: 1em auto;
			padding-left: .9em;
		}

		.svg {
			height: 60px;
			width: 60px;
		}

		.content {
			padding-left: 1em;
			color: $purple1;

			@include xm {
				display: flex;
				flex-wrap: wrap;
			}
		}

		.service {
			padding-left: .5em;

			@include xm {
				font-size: 1.4rem;
				padding-left: .3em;
			}

			@include lg {
				font-size: 1.6rem;
			}
		}

		a {
			display: inline-block;
			margin: .5em 0;
			padding: 0 .5em;

			&:last-child {
				border-left: 1px dotted $purple1;
			}

			&:hover {
				background-color: $white;
			}

			@include xm {
				&:hover {
					background-color: $green1;
					color: $white;
				}
			}
		}
	}

	.green-wrapper {
		width: 100%;
		background-color: $green1;
		padding: .9em;
		display: flex;
		flex-wrap: wrap;

		@include xm {
			display: none;
		}

		a {
			color: $white;

			&:hover {
				color: $green1;
			}
		}
	}

	// SEARCH
	.search-wrapper {

		@include xm {
			display: none;
		}

		.slideout-wrapper {
			z-index: 9999;
		}

		.page-overlay {
			z-index: 999;
		}
		
		.search-trigger {
			position: relative;

			&:before,
			&:after {
				content: ' ';
				position: absolute;
				display: block;
				transition: all 0.1s;
			}

			&:before {
				border: 2px solid $white;
				border-radius: 50%;
				width: 10px;
				height: 10px;
				left: 8px;
				top: -2px;
			}

			&:after {
				height: 2px;
				width: 10px;
				background: $white;
				top: 12px;
				left: 18px;
				transform: rotate(45deg);
			}
		}

		.search-box {
			width: 100%;
		}
	}
}