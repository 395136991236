.clearfix {
    &:before,
    &:after {
        content: " ";
        display: table;
        clear: both;
    }
}

/* -------------------------------------------------------------------------
 Colors
---------------------------------------------------------------------------- */

.sg-color--a .sg-color-swatch {background: $purple1;}

.sg-color--b .sg-color-swatch {background: $purple2;}

.sg-color--c .sg-color-swatch {background: $purple3;}


.sg-color--d .sg-color-swatch {background: $shade1;}

.sg-color--e .sg-color-swatch {background: $shade2;}

.sg-color--f .sg-color-swatch {background: $shade3;}

.sg-color--g .sg-color-swatch {background: $shade4;}

.sg-color--h .sg-color-swatch {background: $shade5;}

.sg-color--i .sg-color-swatch {background: $shade6;}

.sg-color--j .sg-color-swatch {background: $shade7;}

.sg-color--k .sg-color-swatch {background: $shade8;} 

.sg-color--l .sg-color-swatch {background: $shade9;} 

.sg-color--m .sg-color-swatch {background: $shade10;} 



.sg-color--o .sg-color-swatch {background: $green1;}

.sg-color--p .sg-color-swatch {background: $green2;}

.sg-color--q .sg-color-swatch {background: $green3;}


.sg-color--r .sg-color-swatch {background: $white;}

.sg-color--s .sg-color-swatch {background: $black;}



/* -------------------------------------------------------------------------
 Fonts
---------------------------------------------------------------------------- */
/*New*/
.sg-font {font-size: 1.8rem;}

.sg-font-din-Light {font-weight: $DINlight; font-weight: 200;}
.sg-font-din-regular {font-weight: $DINregular; font-weight: 400;}
.sg-font-din-italic {font-weight: $DINitalic; font-weight: 600;}
.sg-font-din-bold {font-weight: $DINbold; font-weight: 700;}

.sg-font-br {font-family: $BR;}


/* -------------------------------------------------------------------------
 Typography
---------------------------------------------------------------------------- */
html {
  font-size: 62.5%; /* Sets up the Base 10 stuff */
}

body {
    font-weight: $DINlight;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 23px;
    color: $black;
    font-family: "droid-serif";
    margin: 0;
    background-color: $shade13;
}

h1 {
    font-weight: $DINbold;
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 49px;
}

h2 {
    font-weight: $DINbold;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 27px;
}

h3 {
    font-weight: $DINbold;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 28px;
}


h4, h5, h6 {
    font-weight: $DINbold;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 21px;
}

footer {
    
    h1 {
        font-weight: $DINbold;
        font-size: 2.1rem;
        line-height: 25px;
    }

    h2 {
        font-weight: $DINbold;
        font-size: 2.0rem;
        line-height: 37px;
    }

    h3{
        font-weight: $DINbold;
        font-size: 1.2rem;
        line-height: 24px;
    }
    
}

p {
    line-height: 1.5;
    margin: 0;
    font-size: 1.5rem;
}

article{
     ul{
        font-size: 1.5rem;
        list-style: disc;
        margin-left: 3em;
    }

    p{
        padding-left: 0.3em;
        padding-right: 0.3em;
    }
}

strong, b {
    font-weight: $DINbold;
    font-weight: 500;
}

em {
    font-style: italic;
}

/* -------------------------------------------------------------------------
 Links
---------------------------------------------------------------------------- */
a {
    color: $purple1;
    text-decoration: none;
    border-bottom: 1px $purple1 dotted;

    &.imagelink{
        border-bottom: none;
    }
}

h1 a, h2 a, h3 a {
    text-decoration: none;
    border-bottom: none;
}

a.read-more {
    font-size: 1.4rem;

    &:after {
        content:' >>';
    }
}

/* -------------------------------------------------------------------------
 Positioning
---------------------------------------------------------------------------- */

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.clear{
    clear: both;
}


/* -------------------------------------------------------------------------
 icons
---------------------------------------------------------------------------- */

[class^="icon-"],
[class*=" icon-"] {
    font-size: 24px;
}

/* -------------------------------------------------------------------------
 Buttons
---------------------------------------------------------------------------- */
.btn, #gform_submit_button_1, input[type="submit"] {
    font-weight: $DINlight;
    font-size: 1.4rem;
    background-color: $green1;
    color: $white;
    padding: .5em 1em;
    min-width: 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-bottom: none;
    border-radius: 5px;
    border-bottom: 3px solid $green3;
    font-size: 1.8rem;
    color: $white;

    &.meer:after {
        content: "> >";
        margin-left: 0.5em;
    }

    &.gerelateerd{
        color: $purple1;
        background-color: $shade8;
        font-size: 1.7rem;
    }


    &.transparent {
        background-color: transparent;
        border: 1px solid $shade5;
        color: $shade3;
    }
}

.btn-diensten{ //ul
    font-size: inherit;
    margin-left: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li{
        margin: 1em 0;
        display: block;
        width: 100%;
        color: $green1;
        background-color: $purple1;
        text-align: center;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;

        @include md {
            width: 48%;
            margin: .5em 1%;
        }

        @include xm {
            width: 31.3333%;
        }

        @include lg {
            width: 23%;
        }

        @include xl {
            width: 31.3333%;
        }

        &:before {
            content: ' ';
            display: block;
            position: absolute;
            height: .4em; width: .4em;
            border-top: 2px solid $white;
            border-right: 2px solid $white;
            transform: rotate(45deg);
            top: calc(50% - 2px); right: 18px;
        }

        &:after {
            content: ' ';
            display: block;
            position: absolute;
            height: 1px; width: 0.6em;
            border-bottom: 2px dotted $white;
            top: 50%; right: 20px;
        }

        &:hover {
            background-color: $purple1 + 20;
        }

        a{
            display: block;
            border-bottom: none;
            position: relative;
            padding: 2em;
            border-left: 6px solid $purple3;
            height: 100%;
        }

        i{
            font-size: 5.0rem;
            color: $white;
            margin: 0 0 0.4em;
        }

        h3{
            color: $white;
            text-align: center;
            margin: 0 0 .5em;
        }

        &.small{
            h3{
                margin-top: 3px;
                line-height: 1;
            }
        }

        p{
            font-size: 1.3rem;
            line-height: 14px;
            color: $shade4;
            text-align: center;
        }
    }
}

.container .gform_wrapper{

    input[type=text],  input[type=url],  input[type=email],  input[type=tel], input[type=number],  input[type=password]{
        font-size: 1.5rem;
        padding-left: 0.5em;
    }

}


/* -------------------------------------------------------------------------
 Lists
---------------------------------------------------------------------------- */

ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        line-height: 22px;
    }
}

ul.arrow-list {

     li {

        &:before {
            content: '>';
            padding-right: 7px;
        }
    }
}

.list-menu{

    h3, li{
        padding: 0.4em;
        padding-right: 0;
        padding-left: 0.5em;
        margin: 0;
        margin-bottom: 0.2em;
        width: 100%;
        font-size: 1.6rem;
    }

    h3{
        background-color: $purple1;
        color: $white;
        font-weight: $DINlight;
           }

    ul{
        list-style: none;
        margin-left: 0;

        li{
           color: $purple1;
           border-bottom: 2px $shade4 dotted;

           a{
            border-bottom: none;
            margin-left: 1em;
            font-weight: $DINlight;
           }
        }

        li:first-child{
            background-color: $shade8;
            border-bottom: none;
            padding-left: 0.5em;
            display: list-item;
            cursor: pointer;
            background-color: $purple1;
            color: $white;
            font-weight: $DINlight;
        }

        li:last-child{
            border-bottom: none;
        }

        i{
            float: right;
            margin-top: -0.2em;
        }
    }

}

/* -------------------------------------------------------------------------
 Tabs
---------------------------------------------------------------------------- */

.tabs{
    ul{
        overflow: hidden;
        width: 100%;
        display: inline-block;
        padding-right: 0.5em;

        li{
            float: left;
            background-color: $shade8;
            margin-right: 2px;
            padding-left: 1em;
            padding-right: 1em;
            height: 38px;
            width: 100%;

            &.current-menu-item{
                background-color: $purple1;
                h3{
                    font-weight: $DINlight;

                    a{
                        color: $white;
                    }
                }
            
                
            }


            h3{
                margin-top: 0.4em;
            }

        }
    }
}

/* -------------------------------------------------------------------------
 Dropdowns
---------------------------------------------------------------------------- */


/* -------------------------------------------------------------------------
 Form elements
---------------------------------------------------------------------------- */

::-webkit-input-placeholder { /* WebKit browsers */
    color: $shade4;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $shade4;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $shade4;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: $shade4;
}

input[type="text"], input[type="search"], .gform_button,input[type="email"], input[type="checkbox"], textarea {
    border-radius: 0;
    border: none;
    background: transparent;
    outline: none;
    font-weight: $DINlight;
    font-size: 1.4rem;
    line-height: 17px;
    background-color: $shade11;
    color: $shade4;
    margin: 0;
    font-family: "droid-serif";
}


input[type="search"] {
    float: left;
    color: $purple1;
    height: 32px;
}

input[type="text"], input[type="email"] {
   height: 32px;
   padding-left: 1.5em;
   padding-right: 0.5em;
   color: $shade2;
}

input[type="search"]::-webkit-input-placeholder {
   color: $purple1;
}

input[type="search"]:-moz-placeholder { /* Firefox 18- */
   color: $purple1;  
}

input[type="search"]::-moz-placeholder {  /* Firefox 19+ */
   color: $purple1;  
}

input[type="search"]:-ms-input-placeholder {  
   color: $purple1;  
}

.search-box {
    text-align: right;
    
    input[type="search"] {
        width: 100%;
        padding-left: 1.5em;
        padding-right: 0.5em;
        color: $purple1;
    }

   .search-submit {
        height: 32px;
        width: 32px;
        border: none;
        border-right: 2px solid $green3 ;
        background-color: transparent;
        cursor: pointer;        
        margin-left: -32px;
        position: absolute;

        i{
          font-size: 1.8rem;  
          color: $purple1;
        }
    }

    input[type="submit"] {
        color: $purple1;
        font-family: $OS, vgc;
    }
}

.subscribe-box{

}

.wysiwyg{
    em{
        font-style: italic;
    }

    ul, ol{
        padding-left: 40px;
         margin: 0.5em auto;
    }

    ul{
        list-style-type: disc;
       

        ul{
            list-style-type: circle;
            padding-left: 20px;

            ul{
                list-style: square;
                padding-left: 40px;
            }
        }
    }

    ol{
        list-style-type: decimal;

        ol{
            list-style-type: decimal;
            padding-left: 20px;

            ol{
                list-style: decimal;
                padding-left: 40px;
            }
        }
    }

    

    .alignleft{
        float: left;
        margin: 1em 1em 1em 0;
    }

    .alignright{
        float: right;
        margin: 1em 0 1em 1em;
    }

    .aligncenter{
        margin: 1em auto;
    }

    a{
        text-decoration: underline;
    }

    p{
        font-weight: 300;
        margin: 0.5em auto;
    }

    table{
        thead{
            td{
                background-color: $shade5;
                color: $black;
                font-weight: 600;
                border: 1px solid $white;
                padding: 10px 15px;
            }
        }

        tfoot{
            tr{
                td{
                     font-weight: 600;
                     background-color: $shade5;
                     color: $black;
                }
            }
        }
    }

    strong, b{
        font-weight: 700;
    }

    h1, h1 strong, h1 em{
       font-weight: 700;
       font-size: 3.6rem;
       color: $black;
       margin: 0.7em 0;
       line-height: 1.3;
       font-style: normal;
    }

    h2, h2 strong, h2 em{
       font-weight: 700;
       font-size: 2.2rem;
       line-height: 1.3;
       color: $black;
       font-style: normal;
    }

    h3, h3 strong, h3 em{
        font-size: 2rem;
        font-weight: 400;
        color: $black;
        margin: 1em 0;
        font-style: normal;
    }

    blockquote, blockquote p{
        margin-left: 20px;
        font-style: italic;
    }

    .gform_body{
        ul{
            list-style: none;
            padding-left: 0;
        }
    }
}

/* -------------------------------------------------------------------------
 tables
---------------------------------------------------------------------------- */


.center{
    text-align: center!important;
}


.article-small .article-title a.heading2{display:block;margin:1em 0;padding-bottom:1em;font-size:.85em}
