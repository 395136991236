.inschrijven{
	ul{
		list-style: none;
		margin: 0;
	}

	.validation_error{
		display: none;
	}

	article{
		.gform_wrapper{
			form{
				.gform_body{
					#gform_fields_2{
						#field_2_1{
							border:none;
							margin: 0px;
							padding: 0px !important;
							.ginput_container
							{
								input
								{
									border:none;
								}
							}
							div.gfield_description.validation_message{
									color: red;
									margin-top: -0.7em;
									margin-bottom: 0.5em;
									font-weight:normal;
									padding: 0px !important;
							}
						}
					}
				}
			}
		}
	}
	

	.gfield{
		width: 100%;
	}

	.gform_wrapper{
		margin: 0;
	}

	.gform_wrapper .gform_footer{
		padding: 0;
		margin: 0;
	}

	.gform_wrapper .top_label input.medium{
		width: 90%;
		font-size: 1.4rem;
		padding: 0 0.5em 0 1.5em;
	}

	.gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit]{
		font-size: 1.4rem;
	}
}

