.wie, .product-groepen{
	h2{
		color: $purple1;
		padding-bottom: 10px;
		margin-bottom: 1em;
	}
}



.product-groepen{
	h3{
		color: $purple1;
		margin: 1em auto;
	}
}

.product-list{
	ul{
		margin-left: 0;

		li{
			display: list-item;
			list-style: none;
			padding-left: 0;
			padding-bottom: 0.5em;

			a{
				margin-left: 0;
			}
		}


        li:first-child{
            background-color: transparent;
			border-bottom: 2px $shade4 dotted;  
			padding-left: 0;          
        }
	}
}

.w-tekst{
	p{
		margin: 0.5em 0;
	}

	table{
		td{
			padding-left: 0.3em;
		}
	}
}

.product{


	.gerelateerd-side{
		margin: 20px 0 0 15px;

		a{
			width: 89%;
			margin-left: 2%;
			margin-bottom: 0.4em;
			box-shadow: 0 0 5px #888888;
		}

		.gerelateerd:hover, .gerelateerd.active{
			background-color: $shade5;
			cursor: pointer;
		}
		

		
	}

	.purple{
		background-color: $purple1;
	}

}

.product-tekst{
	.gform_wrapper{
		ul{
			list-style: none;
			margin-left: 0;

			label, input, textarea, select{
				width: 95%;
			}

			textarea{
				width: 100%;
			}

			li{
				margin-bottom: 1em;
			}
		}
	}

	.product_tab{
		display: none;
	}

	.product_tab.active{
		display: block;
	}

	h1{
		color: $green1;
		margin: 0.5em 0;
		font-weight: $DINregular;
		font-size: 2.5rem;
		line-height: 1;
	}

}



