.block-invest{
	padding: 3em 1em;
	text-align: center;
	margin-top: 3em;
	border-top: 1px solid $shade8;
	.left, .right{
		display: block;
		width: 100%;
		text-align: center;
		.btn{
			margin: .5em 0;
		}

		@media screen and (min-width: 980px) {
			display: inline;
			width: auto;

			.heading1{
				display: inline;
			}
			.btn{
				margin: 0 .5em;
				transform: translateY(-5px);
			}
		}
	}
}