.sidebar-home{
	.sidebar{

		.block.block-light.producten{
			background-color: transparent;
			border-right: 1px $shade4 dotted;
			border-left: 1px $shade4 dotted;

			article{
				padding-top: 0;
			}
		}

		.gfield{
			width: 100%;
		}

	}
}



#gform_fields_1{
	#field_1_3, #field_1_5{
		/*float: left;
		width: 50%;*/
		clear: none;

		input{
			width: 95%;
		}

		&.gfield_error{
			width: 47%;
		}
	}

    .ginput_left, .ginput_right{
      float:none;
      width:100%;
    }

	list-style: none;
	margin: 0;


}

#gform_submit_button_1{
	cursor: pointer;
}




#berekening_form{
	input[type="submit"]{
		display: none;
	}
}

#berekening{
	margin-bottom: 1em;
}

.gform_validation_container{
	display: none;
}