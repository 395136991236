/* -------------------------------------------------------------------------
 Cookie warning
---------------------------------------------------------------------------- */

body > div#cookiewarning{
  background-color: $shade5;
  div#back{
    z-index: 0;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: auto;
    padding-top: 0.5em;
    background-color: $shade5;
    div{
      width: 100%;
      text-align: center;
      margin: 0 auto;
      padding: 0;
      overflow: hidden;
      background-color: $shade5;

      p{
        font-size: 1.2rem;
        margin-top: 0.25em;
        margin-bottom: 0;
        height: auto;
        display: inline-block;
        float: left;
        width: 100%;
        text-align: center;
        font-weight: normal;
        font-family: 'Open Sans', sans-serif;
       	color: $purple1;
      }

      #buttons{
        margin: 0em 0 0.5em 0em;
        display: inline-block;
        float: left;
        width:100%;
        text-align: center;
        margin-top: 0.5em;

        a{
          float: none;
          padding: 0.3em 0.5em;
          text-align: center;
          font-size: 1.2rem;
          display: inline-block;
          border: none;
          width: 9em;
          font-weight: normal;
        }

        #cookiewarningOK{
          background-color: $purple1;
          margin-left: 1em;
          text-decoration: none;
        }

        #cookiewarningnotOK{
          background-color: #d4d4d4;
          color: #ffffff;
          display: none;
        }

      }

    }
  }
}