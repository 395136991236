.diensten{
	@media screen and (min-width: $xmedium-width) {
		.blocks {
			display: flex;
			justify-content: space-between;
		}
	}

	margin: 2em auto;
	box-sizing: border-box;
	width: 100%;

	.heading1{
		text-align: left;
	}

	.blocks{
		margin-bottom: 3em;
	}

	.link-item-container{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -1em;
	}
	.link-item {
		width: 100%;
		display: block;
		text-align: center;
		padding: 0 1em;

		@media screen and (min-width: $xmedium-width) {
			width: 33.333333%;
			display: inline-block;
		}

		a{
			background-color: $purple1;
			color: $white;
			padding: 1em 0;
			display: block;
			border-left: 6px solid $purple3;
			border-bottom: none;
			line-height: 1.3;
			position: relative;
			display: block;
			margin-bottom: 1em;

			transition: all .3s;

			font-size: 2rem;
			@media screen and (min-width: $medium-width) {
				font-size: 2rem;
			}
			@media screen and (min-width: $xmedium-width) {
				font-size: 2.3rem;
			}

			span{
				padding: 1em;
				width: 100%;
			    text-align: center;
			    color: $green1;
			}

			&:hover {
				background-color: $purple1 + 20;
			}
		}

		i{
			display: block;
			text-align: center;
			font-size: 8rem;
			padding: 0 0 0.2em 0;
			color: $white;
		}
	}
}
